import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, first, map, startWith, switchMap, tap } from 'rxjs';
import { AddressesService } from '../services';
import { Address } from '../models';

export const asyncWastecardValidator = (service: AddressesService, address: Address): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return control.valueChanges.pipe(
      startWith(control.value),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(() => service.validatePassNumber(address.houseNumber, address.addition, address.postalCodeFull, control.value)),
      map(result => (!result.success ? { invalidPassNumber: true } : {})),
      first()
    );
  };
};
