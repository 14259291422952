import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AddressFormComponent } from './components/address-form/address-form.component';

import * as fromStore from './store';
import { DynamicFormModule } from '@teamfoster/dynamic-forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { CoreModule } from '../core/core.module';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AlertComponent } from '../standalone/alert/alert.component';
import { WasteCardNumberFormComponent } from './components';

@NgModule({
  declarations: [AddressFormComponent, WasteCardNumberFormComponent],
  imports: [
    CommonModule,
    DynamicFormModule,
    CoreModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    DictionaryNgrxModule,
    AlertComponent,
  ],
  exports: [AddressFormComponent, WasteCardNumberFormComponent],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', floatLabel: 'always', color: 'accent' } }],
})
export class AddressesModule {}
