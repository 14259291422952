import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, map, withLatestFrom } from 'rxjs/operators';

import * as fromStore from '../store';
import * as fromRoot from '../../store';

import { Observable, combineLatest, of } from 'rxjs';
import { isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AddressAuthGuard {
  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }

    return combineLatest([
      this.store.select(fromStore.getAddress),
      this.store.select(fromStore.getAddressInitialized),
      this.store.select(fromStore.getAddressInitializing),
      this.store.select(fromRoot.getRouterState),
    ]).pipe(
      tap(([signedIn, checked, checking, route]) => {
        if (!checked && !checking) {
          this.store.dispatch(fromStore.InitAddress());
          return;
        }
        if (checked && !!!signedIn?.houseNumber) {
          this.store.dispatch(fromRoot.Go({ path: ['/aanmelden', 'inloggen'], queryParams: { returnUrl: route.state.url } }));
        }
      }),
      map(([signedIn, checked, checking]) => !!signedIn?.houseNumber && checked && !checking),
      filter(loaded => loaded),
      take(1)
    );
  }
}
