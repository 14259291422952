<form [formGroup]="addressForm">
  <div class="row">
    <div class="col-12 col-md-4">
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Postcode</mat-label>
        <input matInput formControlName="postalCode" required autocomplete="postal-code" placeholder="1234AB" />
        <mat-error *ngIf="addressForm.get('postalCode')?.invalid">
          {{ getErrorMessage(addressForm.get('postalCode')) }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6 col-md-4">
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Huisnr.</mat-label>
        <input matInput formControlName="houseNumber" required placeholder="Nummer" autocomplete="none" />
        <mat-error *ngIf="addressForm.get('houseNumber')?.invalid">
          {{ getErrorMessage(addressForm.get('houseNumber')) }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6 col-md-4">
      <mat-form-field
        appearance="fill"
        color="accent"
        [class.is--loading]="additionsLoading$ | async"
        [class.mat-form-field-readonly]="((additions$ | async) || []).length <= 0 && (additionsLoading$ | async) === false"
      >
        <mat-label>Toevoeging</mat-label>
        <mat-select formControlName="houseNumberAddition" [placeholder]="'toevoeging-dropdown-placeholder' | fromDictionary">
          <mat-option *ngFor="let a of additions$ | async" [value]="a.value">{{ a.name }}</mat-option>
          <mat-option *ngIf="!(additions$ | async)?.length && !!addressForm.get('postalCode')?.value" [value]="''">
            Geen toevoeging
          </mat-option>
        </mat-select>

        <mat-error *ngIf="addressForm.get('houseNumberAddition')?.invalid">
          {{ getErrorMessage(addressForm.get('houseNumberAddition')) }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <app-alert
    *ngIf="addressNotFound(additionsError$ | async)"
    class="d-block mb-4"
    [status]="'danger'"
    [message]="'adres-formulier-adres-niet-gevonden' | fromDictionary"
  ></app-alert>

  <app-alert
    *ngIf="(additionsError$ | async) && !addressNotFound(additionsError$ | async)"
    class="d-block mb-4"
    [status]="'warning'"
    [message]="'adres-formulier-validatie-fout' | fromDictionary"
  ></app-alert>
</form>
