import { ChangeDetectionStrategy, Component, Signal, ViewChild, computed, inject, input } from '@angular/core';
import { Address } from '../../models';
import { Store } from '@ngrx/store';
import { ClearWasteCardNumber, SetWasteCardNumber, getAddress, getWasteCardNumber } from '../../store';
import { DynamicFormField, FormComponent } from '@teamfoster/dynamic-forms';
import { Validators } from '@angular/forms';
import { asyncWastecardValidator } from '../../validators';
import { AddressesService } from '../../services';

@Component({
  selector: 'app-waste-card-number-form',
  templateUrl: './waste-card-number-form.component.html',
  styleUrl: './waste-card-number-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WasteCardNumberFormComponent {
  private store = inject(Store);
  private addressService = inject(AddressesService);

  address$ = this.store.selectSignal(getAddress);
  wasteCardNumber$ = this.store.selectSignal(getWasteCardNumber);

  @ViewChild('form') form!: FormComponent;

  formFields$: Signal<DynamicFormField[]> = computed(() =>
    this.address$()
      ? [
          {
            fieldType: 'text',
            label: 'ROVA pasnummer',
            name: 'wasteCardNumber',
            validators: [Validators.required],
            asyncValidators: [asyncWastecardValidator(this.addressService, this.address$()!)],
            order: 1,
            subscriptSizing: 'dynamic',
            updateOn: 'blur',
          },
        ]
      : []
  );

  save(address: Address, number: string) {
    if (this.form.form.valid) {
      this.store.dispatch(
        SetWasteCardNumber({
          housenumber: +address.houseNumber,
          addition: address.addition || '',
          postalCode: address.postalCodeFull,
          number,
        })
      );
    }
  }

  clear() {
    this.store.dispatch(ClearWasteCardNumber());
  }
}
