import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { DynamicFormField } from '@teamfoster/dynamic-forms';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  map,
  Observable,
  Subject,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import * as fromStore from '../../store';
import { Address } from '../../models';
import { ClearAddress } from '../../store/actions/address.action';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @Input() address!: Address | null;
  prefix = 'adres-formulier';

  additions$: Observable<any[]>;
  additionsLoading$: Observable<boolean>;
  additionsLoaded$: Observable<boolean>;
  street$: Observable<string | null>;
  town$: Observable<string | null>;
  additionsError$: Observable<any>;

  valid$!: Observable<boolean>;

  houseNumberAddition = this.fb.control<string | null>(null, this.notNullValidator);

  addressForm = this.fb.group({
    postalCode: ['', [Validators.required, Validators.minLength(6)]],
    houseNumber: ['', Validators.required],
    houseNumberAddition: this.houseNumberAddition,
  });

  @Output() formChanges = new EventEmitter();

  private _unsubscribe = new Subject<void>();
  private dict = new FromDictionaryPipe(this.lang);

  notNullValidator(control: AbstractControl<any, any>): ValidationErrors | null {
    return control.value === null ? { required: true } : null;
  }

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private lang: LanguageService,
    public cd: ChangeDetectorRef
  ) {
    this.additions$ = this.store.select(fromStore.getValidAdditions);
    this.additionsLoading$ = this.store.select(fromStore.getValidAdditionsLoading);
    this.additionsLoaded$ = this.store.select(fromStore.getValidAdditionsLoaded);
    this.town$ = this.store.select(fromStore.getAdditionsTown);
    this.street$ = this.store.select(fromStore.getAdditionsStreet);
    this.additionsError$ = this.store.select(fromStore.getAdditionError);
  }

  ngOnInit(): void {
    this.valid$ = combineLatest([
      this.street$,
      this.addressForm.valueChanges,
      this.additionsError$,
      this.additionsLoading$,
      this.additionsLoaded$,
      this.addressForm.statusChanges,
    ]).pipe(
      //tap(([street, value, error, loading]) => console.log('addressform comp:', street, value, error, loading)),
      map(([street, value, error, loading, loaded]) => !!street && this.addressForm.valid && !error && !loading && loaded)
    );

    this.additionsLoading$.pipe(takeUntil(this._unsubscribe)).subscribe(loading => {
      if (loading) {
        // clear addition when new additions is loading
        this.addressForm?.patchValue({
          houseNumberAddition: null,
        });
      }

      // clear new address
      if (loading && this.address && this.address?.postalCodeFull !== this.addressForm.value.postalCode) {
        this.store.dispatch(ClearAddress({}));
      }
    });

    //this.additionsLoaded$.pipe(takeUntil(this._unsubscribe)).subscribe(l => {
    //  //if (l && this.address?.addition) {
    //  //  this.addressForm?.patchValue({
    //  //    houseNumberAddition: this.address?.addition?.toUpperCase() || '',
    //  //  });
    //  //}
    //  this.initAdditionValue(additions);
    //});

    this.additions$.pipe(takeUntil(this._unsubscribe)).subscribe(additions => {
      this.initAdditionValue(additions);
    });

    this.addressForm.valueChanges
      .pipe(
        takeUntil(this._unsubscribe),
        map(a => ({ postalCode: a.postalCode, houseNumber: a.houseNumber })),
        filter(a => (a.postalCode && a.houseNumber && a.postalCode.length >= 6 && parseInt(a.houseNumber) > 0) || false),
        distinctUntilChanged((a, b) => a.houseNumber === b.houseNumber && a.postalCode === b.postalCode),
        debounceTime(1000)
      )
      // tslint:disable-next-line: deprecation
      .subscribe(a => {
        this.addressForm.get('houseNumberAddition')?.setValue(null);
        this.store.dispatch(ClearAddress({}));

        this.cd.detectChanges();
        this.store.dispatch(fromStore.LoadValidAdditions({ postalCode: a.postalCode || '', houseNumber: parseInt(a.houseNumber || '0') }));
      });

    if (this.address) {
      this.addressForm.setValue({
        postalCode: this.address?.postalCodeFull || '',
        houseNumber: String(this.address?.houseNumber || '') || '',
        houseNumberAddition: this.address?.addition?.toUpperCase() || null,
      });

      this.cd.detectChanges();

      this.store.dispatch(
        fromStore.LoadValidAdditionsSuccess({ data: { street: this.address.street, town: this.address.city, additions: [] } })
      );

      this.addressForm.updateValueAndValidity();
    }
  }

  // Init with value if only one addition is available or an empty addition is available
  initAdditionValue(additions: { name: string; value: string }[]) {
    if (this.addressForm?.get('houseNumberAddition')?.value) {
      return;
    }
    if (!this.addressForm.get('postalCode')?.value || (additions?.length > 1 && !additions?.find(a => a.value === ''))) {
      this.addressForm.get('houseNumberAddition')?.setValue(null);
      this.store.dispatch(ClearAddress({}));
      return;
    }
    if (additions?.length === 0 || additions?.length === 1 || additions?.find(a => a.value === '')) {
      this.addressForm.patchValue({
        houseNumberAddition: additions?.[0]?.value || '',
      });
    }

    this.cd.detectChanges();
    this.addressForm.updateValueAndValidity();
  }

  getErrorMessage(ctrl: any) {
    if (ctrl.hasError('required')) {
      return 'Dit veld is verplicht';
    }

    if (ctrl.hasError('email')) {
      return 'Dit is geen correct e-mailadres';
    }

    if (ctrl.hasError('postalCode')) {
      return 'Dit is geen correct postcode';
    }

    if (ctrl.hasError('tel')) {
      return 'Dit is geen correct telefoonnummer';
    }

    if (ctrl.hasError('maxlength')) {
      return 'Deze postcode bevat teveel cijfers';
    }

    if (ctrl.hasError('minlength')) {
      return 'Deze waarde bevat te weinig karakters';
    }

    if (ctrl.hasError('pattern')) {
      return 'Dit is geen correcte waarde';
    }

    if (ctrl.hasError('address_not_found')) {
      return 'Dit adres is niet gevonden. Klopt de bovenstaande postcode en huisnummer? Zo ja vul onderstaande woonplaats en straatnaam in';
    }

    return null;
  }

  addressNotFound(error: any) {
    return error && error['error']?.indexOf('No address found') >= 0;
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
