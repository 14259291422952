@if (wasteCardNumber$(); as number) {
  <h2 class="fw-bold text-primary fs-body-md font-secondary">{{ 'app-pasnummer-toevoegen-titel' | fromDictionary }}</h2>
  <p class="lh-base">
    Uw pasnummer:
    <strong>{{ wasteCardNumber$() }}</strong>
  </p>

  <div class="text-center">
    <button class="button button--secondary" (click)="clear()">
      {{ 'app-pasnummer-verwijderen-knoptekst' | fromDictionary }}
    </button>
  </div>
} @else {
  <h2 class="fw-bold text-primary fs-body-md font-secondary">{{ 'app-pasnummer-toevoegen-titel' | fromDictionary }}</h2>
  <p class="lh-base">{{ 'app-pasnummer-toevoegen-tekst' | fromDictionary }}</p>

  @if (formFields$().length > 0) {
    <fstr-dynamic-form
      [config]="formFields$()"
      (formSubmit)="save(address$()!, $event.wasteCardNumber)"
      [appearance]="'fill'"
      [showSubmit]="false"
      #form
    >
      <div class="text-center" slot="form-actions">
        <!-- <app-alert class="d-block mt-4" [status]="'danger'" [message]="'Error tekst'"></app-alert> -->
        <button type="submit" class="button button--secondary mt-3" [disabled]="!form.form.valid">
          {{ 'app-pasnummer-opslaan-knoptekst' | fromDictionary }}
        </button>
      </div>
    </fstr-dynamic-form>
  }
}
